<template>
    <div class="login-main"> 
        <h1>SignUp</h1>   
        <!-- <div class="signup-title">
            <span>SIgn</span>
            <span>Up</span>
        </div>      -->
        <div class="container">  
            <div class="vx-row justify-center">                
                <div class="vx-col lg:w-10/12 w-full">          
                    <div class="vx-row justify-center login-inner">
                        <div class="vx-col md:w-1/3 w-full p-0">
                            <div class="login-form">
                                <h2>Login</h2>
                                <form data-vv-scope="loginForm">
                                    <div class="form-grp">                                            
                                        <vs-input name="email" label-placeholder="Email"  v-model="email" class="w-full" />                                
                                        <feather-icon icon="UserIcon"></feather-icon>
                                    </div>
                                    <div class="form-grp">                                                                             
                                        <vs-input type="password" name="password" label-placeholder="Password" v-validate="'required'" v-model="password" class="w-full" @keyup.enter="login()" />                                
                                        <feather-icon icon="KeyIcon"></feather-icon>
                                    </div>
                                    <div class="flex flex-wrap items-center justify-center mt-8 w-full">
                                        <vs-button color="success" id="user-login" :disabled="button_loading" type="filled" 
                                        class="w-full mt-4">Login</vs-button>
                                    </div>                                                                      
                                    <router-link to="Forgotpassword" class="forgot-pass float-right">Forgot Password? </router-link>                                                                        
                                </form>
                            </div>
                        </div> 
                        <div class="inner-bg"></div>          
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            email: null,
            password: null
        };
    },
    methods: {
       
    }
    
}
</script>

<style lang="scss">
.login-main {
    align-content: flex-end;
    position: relative;
    min-height: 100vh;
    z-index: 1;
    // padding: 40px 0px;
    padding: 160px 0px 50px;
    display: flex;     
    align-items: center;        
    background: #f5f5f5;   
    // .signup-title{
    //     float: left;
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     left: 0;
    //     width: 100%;
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;        
    //     span{                    
    //         font-size: 12vw;
    //         color: #ffffff;
    //         width: 100%;
    //         text-align: center;
    //         text-transform: uppercase;
    //         font-weight: 700;
    //         text-shadow: 0px 4px 28px rgba(var(--vs-success), 0.5);
    //         width: 0.9em;
    //         word-break: break-all;
    //         line-height: 0.9; 
    //     }    
    //     span:first-child{
    //         margin-left: 130px;
    //     }    
    //     span:last-child{
    //         margin-right: 150px;
    //     }    
    // }
    h1{
        position: absolute;
        top: -40px;
        font-size: 19vw;
        color: #ffffff;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        text-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    } 
    .login-inner{
        background: rgba(var(--vs-success), 1);  
        position: relative;
        float: left;
        width:100%;
        border-radius: 1.5em;
        box-shadow: 0px 14px 30px 1px rgba(0,0,0,0.15);
        z-index: 1;
        overflow: hidden;
        &::before{
            background-image: url(../../assets/images/login-bg.png);
            background-repeat: repeat;
            position: absolute;
            height: 100%;
            width: 50%;
            left: 0;
            top:0;            
            content: "";   
            opacity: 0.25;
        }
        &:after{
            position: absolute;
            height: 100%;
            width: 50%;
            right: 0;
            top:0;
            background: #fff;
            content: "";
            border-radius:  0px 1.5em 1.5em 0px;
            z-index: -1;
        }   
        .inner-bg{
            position: absolute;
            right: -150px;
            top:50%;
            background: #ffffff;
            border: 46px solid rgba(var(--vs-success), 0.3);
            border-radius: 100%;
            width: 300px;
            height: 300px;
            transform: translateY(-50%);
            &:after{
                position: absolute;
                right: -90px;
                top:-98px;
                width: 400px;
                height: 400px;                
                border: 1px solid rgba(var(--vs-success), 0.3);
                content: "";
                border-radius: 100%;
                z-index: -1;
            }
            &:before{
                position: absolute;
                left: -99px;
                top: 0px;
                width: 32px;
                height: 32px;                
                background: rgba(var(--vs-success), 0.3);
                content: "";
                border-radius: 100%;                
            }
        }
    }
    
    .login-form{
        float: left;
        background: #fff;
        border-radius: 1.5em;
        width: 100%;
        box-shadow: 0px 4px 10px 1px rgba(0,0,0,0.2);
        margin: 100px 0px;
        padding: 32px;
        position: relative;
        &:after{
            position: absolute;
            left: 100%;            
        }
        h2{
            float: left;
            width: 100%;
            position: relative;
            padding-bottom: 16px;
            margin-bottom: 16px;
            text-align: center;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 500;
            color: #222;
            letter-spacing:1px;
            &:after{
                position: absolute;
                left: 0;
                bottom: 0;
                width: 30px;
                right: 0;
                margin: 0 auto;
                content: "";
                background: rgba(var(--vs-success), 1);
                height: 1px;               
            }
        }
        .form-grp{
            float: left;
            width: 100%;
            margin-bottom: 30px;   
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;   
            position: relative;
            .feather-icon {
                position: absolute !important;
                right: 0;
                bottom:8px;
                svg{
                    width: 20px;
                    height: 20px;
                    color: #ccc;
                }
            }               
            .vs-input{
                float: left;
                .input-span-placeholder{
                    padding: 8px 0px !important;
                    color: #999;
                    font-weight: 500;
                }
            }
            .vs-input input{
                border: 0px !important;
                border-bottom: 1px solid #e2e2e2 !important;
                border-radius: 0px;
                padding: 8px 30px 8px 0px !important;
                &:focus{
                    box-shadow: none !important;
                    border-bottom: 1px solid #cccccc !important;
                }
            }                
                                     
        }
        .forgot-pass{
            text-align: center;
            font-weight: 500;
            color: #333;
            float: left;                                        
            width: 100%;
            margin-top: 24px;
            &:hover{
                color : rgba(var(--vs-success), 1);
            }
        } 
    }
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {  
  -webkit-text-fill-color: #666;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
